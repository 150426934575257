import React from 'react';
// Pieces imports
import { SEO, Heading, Box } from 'pieces';
// sections

const MessebauPage = () => (
  <>
    <SEO title='Messebau' />
    <Box sx={{ minHeight: '100vh' }}>
      <Heading>Messebau</Heading>
    </Box>
  </>
);

export default MessebauPage;
